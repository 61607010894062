
<template>
  <h1>Bookl.ee</h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'App',
  components: {},
  async created() {
    try {
      const res = await axios.get(`https://test-api.bookl.ee/api/v1/sessions/current`, { withCredentials: true });
      this.items = res.data;
    } catch (error) {
      console.log(error);
    }
  },
});

</script>

<style scoped>
</style>
